<template>
  <van-cell-group title="店铺首页">
    <van-cell :title="page.home" />
  </van-cell-group>
  <van-cell-group title="我的订单">
    <van-cell :title="page.order" />
  </van-cell-group>
  <van-cell-group title="个人中心">
    <van-cell :title="page.user" />
  </van-cell-group>
  <van-cell-group title="购物车">
    <van-cell :title="page.cart" />
  </van-cell-group>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      url: 'https://shop.feddon.com/',
      page: {},
      shop: {},
      storeId: Cookies.get('storeId'),
      mpQr: false
    })
    const init = () => {
      post('/util.getAllUrls', {
      }).then(res => {
        state.page = {
          home: res.data.shop + '?storeId=' + state.storeId,
          user: res.data.shop + 'user?storeId=' + state.storeId,
          order: res.data.shop + 'order?storeId=' + state.storeId,
          cart: res.data.shop + 'cart?storeId=' + state.storeId
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
</style>
